import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import installationImg from '../../../assets/images/gate-installation-img.jpg'

class gateInstallationPage extends React.Component {
  render() {
    const siteTitle =
      'Gate Installation Services | J & J Automatic Gate Service & Design'
    const siteDescription =
      'We provide gate installation services for both commercial and residential properties in Dallas, Fort Worth, Arlington, and surrounding areas.'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Gate Installation Services</h1>
            <hr />
            <p className="text-white mx-auto">
              The team at J & J Gates Service and Design are professional
              residential and commercial installers servicing the entire DFW
              metroplex. We know that a nice gate can instantly raise the value
              of your home or business property, as well as increase security.
              If you have a large security gate installed around your property,
              intruders will look for an easier target in most cases. Our team
              has over 12 years of experience and knows how to properly install
              all types of gates, fences, and access control systems. Our
              installers will listen to your needs and suggest the best solution
              for your specific requirements.{' '}
              <strong class="primary-color">
                Call us at <a href="tel:+1-817-466-2794">(817) 466-2794</a> to
                learn more about our gate installation services in Dallas and
                Fort Worth, TX.
              </strong>
            </p>
          </div>
        </div>

        <div className="container d-padding-t d-padding-b">
          <div className="row d-padding-b">
            <div className="col-md-8">
              <h4 className="mt-0">Residential Driveway Gate Installation</h4>
              <hr className="ml-0" />
              <p>
                The residential installation process is simple! We listen and
                ask questions to make sure we understand your requirements, and
                then come to your home with specialized tools and equipment and
                build around your specifications. Your driveway gate will be as
                tall and wide as needed so your automobiles, camper, and boat
                will easily fit through the entrance. Whether you want a gate
                for security or aesthetic purposes, you can count on J & J Gates
                Service and Design to meet all your expectations. Learn more
                about{' '}
                <Link to="/">residential driveway gate installations</Link>.
              </p>
            </div>
            <div className="col-md-4">
              <LazyLoadImage
                className="w-100"
                src={installationImg}
                title="Gate Installation"
                alt="Gate"
              />
            </div>
          </div>

          <div className="set-width text-center mx-auto d-padding-b">
            <h4 className="mt-0">Commercial Gate Installation</h4>
            <hr />
            <p>
              We are the leading commercial gate installer in Arlington and the
              Dallas-Fort Worth metroplex. If you are looking for a company that
              offers the best services, you should check out what we have to
              offer. We carry and customize a wide array of gate designs to help
              ensure the perfect type and appearance of a gate for your
              business. If you house or receive large delivery trucks, you will
              want to make sure they will fit through your entrance with ease.
              We will do everything possible to make sure all your commercial
              requirements are understood and met, and that nothing important is
              overlooked. Learn more about{' '}
              <a href="/">commercial gate installations</a>.
            </p>
          </div>

          <div className="set-width text-center mx-auto">
            <h4 className="mt-0">Free Estimates</h4>
            <hr />
            <p>
              When we handle your installation, you can count on us to clean up
              after ourselves so that all you see is a beautiful gate after we
              are done. We also have excellent reviews from our past customers
              because we treat each job like it is the most important one we
              will ever do. If you would like to know more about becoming one of
              our satisfied customers, please contact us today for more
              information and a free estimate!{' '}
              <strong>
                When you need residential or commercial gate installation in
                Dallas and Fort Worth, TX, call J & J Gates Service and Design
                at <a href="+1-817-466-2794">(817) 466-2794</a>!
              </strong>
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default gateInstallationPage

export const gateInstallationPageQuery = graphql`
  query gateInstallationPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
